.App {
  /*text-align: center;*/
  background-color: #e7e7e7;
  min-height: 100vh;
  font-family: 'Bai Jamjuree', sans-serif !important;
  height: 100%;
}

.root {
  height: 100%;
}

.body {
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

.ant-input-xl {
  font-size: 18px !important;
}

.ant-col h3,.ant-col h2 {
  font-weight: bold;
}

.widget-info-modal .ant-modal-header {
  padding: 5px;
  background-color: #d2e1da;
}

.sk-logo {
  width: 300px;
  position: absolute;
  left: 0;
  cursor: pointer;
}

.sk-logo.simplified {
  width: 150px;
  display: none;
}

.sk-w-out-search {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 5px;
}

.sk-w-out-search.simplified {
  position: unset;
  padding: 0 14% 0 0;
  top: 0;
}

h2.simplified {
  margin-bottom: 0;
}

.sk-w-out-container {
  padding: 10px;
  height: calc(100% - 20px);
  overflow: auto;
}

.sk-w-out-container.simplified {
  padding: 0;
}

.sk-right-col {
  border: 1px solid #b9b9b9;
  margin: 5px;
  padding: 5px;
  height: 100%;
  overflow: hidden;
}

.sk-right-col.simplified {
  margin: 0px;
  padding: 0px;
}

.sk-right-col > div > :first-child {
  margin: 0 20px 20px 20px !important;
}

.sk-right-col.simplified > div > div {
  margin: 10px 0px 0px 0px !important;
}

.sk-right-col.simplified > div > :first-child {
  margin: 0 !important;
}


.sk-main.simplified {
  padding: 0;
}

.sk-progress {
  position: absolute;
  bottom: 50%;
  right: 13px;
}

.sk-progress.simplified {
  position: absolute;
  bottom: -30px;
  right: 120px;
  top: 2px;
  z-index: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-btn-link {
  color: #0b5499 !important;
}
/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/
